import './App.css';
import { 
  useState, 
  useEffect,
  //useCallback, 
  useMemo,
  useReducer } from "react";
import parse from 'html-react-parser';
import {getPosts} from "./firebaseConfig";
import Sval from 'sval';

// Create a interpreter
const interpreter = new Sval({
  // ECMA Version of the code
  // 3 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15
  // or 2015 | 2016 | 2017 | 2018 | 2019 | 2020 | 2021 | 2022 | 2023 | 2024
  // or "latest"
  ecmaVer: 'latest',
  // Code source type
  // "script" or "module"
  sourceType: 'script',
  // Whether the code runs in a sandbox
  sandBox: true,
})
const getTests = async (callback, collection_)=>{

        let data = [{
          "lastModifyDt": {
              "seconds": 1723867200,
              "nanoseconds": 483000000
          },
          "solution": "<div>&nbsp;</div><div>There is something special about this one.</div>",
          "ccode": "(()=>{ \tarr = inp; \tif(arr == null || arr.length == 0) { \t\treturn [1]; \t} \tarr = arr.join(\"\").split(\"\"); \trev = []; \tarr = arr.filter((e,i)=>{return /^[A-Za-z0-9]+$/.test(e);}).map((e,i)=>{rev=[e,...rev];return e;}); \treturn arr.join(\"\") == rev.join(\"\")?[1]:[-1]; })()",
          "icode": "(()=>{ \tarr = inp; \tif(arr == null || arr.length == 0) { \t\treturn [1]; \t} \tarr = arr.join(\"\").split(\"\"); \trev = []; \tarr.map((e,i)=>{rev=[e,...rev];return e;}); \treturn arr.join(\"\") == rev.join(\"\")?[1]:[-1]; })()",
          "acode": "(cres == null && ires == null) || (cres != null && ires != null && typeof(cres) == 'object' && typeof(ires) == 'object' && JSON.stringify(cres) == JSON.stringify(ires))",
          "vcode": "inp == \"\" || /^([A-Za-z0-9\\\\\\/\\[\\],\"'\\-@]|\\s)+$/.test(inp)",
          "title": "Test Exercise - 2024-08-17",
          "background": "<div><div>What is a palindrome?</div><div>The customer liked the results of The Reverse String Function, and asked FakeCompany to create another function.  This time, FakeCompany has been tasked with creating a function to test if a string is a palindrome.  A palindrome is a sequence that reads the same forwards and backwards.  FakeCompany would like to enlist your help to create edge cases that have not been considered.</div><div>Provide your own input.  Format: string that can contain numbers or letters.  Example: 123 or abc.</div></div>",
          "msg": ""
      },{
          "solution": "<div>&nbsp;</div><div>Provide an array that contains one number and one string.</div>",
          "acode": "(cres == null && ires == null) || (cres != null && ires != null && typeof(cres) == 'object' && typeof(ires) == 'object' && JSON.stringify(cres) == JSON.stringify(ires))",
          "icode": "(()=>{ arr = inp; r = []; a = []; if(typeof(arr) === \"undefined\" || arr == null) { \tr = null; \treturn r; } for(let i = arr.length-1; i >= 0; i--) { \tlet el = arr[i]; \tif(el != null && typeof(el) === \"number\") { \t\tr.push(el); \t} else if(el != null && typeof(el) === \"string\") { \t\ta.push(el); \t} } if(a.length > 0){  r = a; } return r; })()",
          "title": "Test Exercise - 2024-08-09",
          "vcode": "inp == \"\" || /^([A-Za-z0-9\\\\\\/\\[\\],\"'\\-@]|\\s)+$/.test(inp)",
          "background": "<div><div>FakeCompany has been tasked with creating a function that reverses a given input object array.  One of the developers has implemented a solution and is concerned that a test case has not been considered.  Based on the test cases that the developer wrote, can you find another test case to include?</div><div>&nbsp;</div><div> <ul> <li>Null input</li> <li>Empty array</li> <li>An array containing one string</li> <li>An array containing one number</li> <li>An array containing an even number of elements</li> <li>An array containing an odd number of elements</li> <li>An array with many elements</li> </ul> </div><div>Provide your own input.  Format: an array that can contain numbers or letters starting with &#91; and ending with &#93;.  Example: &#91;1,2,3&#93; or &#91;\"a\",\"b\",\"c\"&#93;.</div></div>",
          "lastModifyDt": {
              "seconds": 1723176000,
              "nanoseconds": 961000000
          },
          "ccode": "(()=>{ arr = inp; r = []; if(typeof(arr) === \"undefined\" || arr == null) { \tr = null; \treturn r; } for(let i = arr.length-1; i >= 0; i--) { \tlet el = arr[i]; \tr.push(el); } return r; })()",
          "msg": ""
      }];

        callback(data);

        


    };

function Testercise(props) {

    const [posts,setPosts] = useState([]);
    const [toggle,setToggle] = useState([]);
    const [inp,setInp] = useState([]);

	useEffect(()=>{
		getTests((data)=>{
			const d = data;
			setPosts((prev)=>{
        let i = 0;
        while(i < d.length) {
          d[i].msg = "";
          i++;
        }
        setInp(prev=>{
          const arr = prev;
          let t = 0;
          while(t < d.length) {
            arr.push("");
            t++;
          }
          return arr;
        });
        setToggle(prev=>{
          const arr = prev;
          let t = 0; 
          while(t < d.length) {
            arr.push(false);
            t++;
          }
          return arr;
        });
				return d;
			});
      props.setLoaded(prev=>true);
		},"testercise");
	},[]

	);

	const toggleSolution = (e,i)=>{
		setToggle((p)=>{
      const arr = p;
			return arr.map((el,idx)=>idx == i ? !el : el);
		});
	};

  const handleInput = (e,i)=>{
    setInp(prev=>{
      const arr = prev;
      let v = e.target.value;
      //if(v == "" || /^([A-Za-z0-9\\\/\[\],"'\-@]|\s)+$/.test(v)) {
        return arr.map((el,idx)=>idx == i ? v : el);
      //} else {
      //  return arr;
      //}
    });
    setPosts(prev=>{
      const arr = prev;
        return arr.map((el,idx)=>{
          if(idx == i) {
            el.msg = "";
          }
          return el;
        });
    });
    
  };

  const handleSubmit = (e,i)=>{
      let v = inp[i];
      let res = validate(posts[i].vcode,v);
      if(res) {
        res = testInputMatches(posts[i].ccode,posts[i].icode,v,posts[i].acode);
      } else {
        res = -1;
      }
    
      setPosts(prev=>{
        const arr = prev;
        let msg = "";
        if(res === -1) {
          msg = "Please check the test input.";
        } else if(res) {
          msg = "This test case is already handled.";
        } else {
          msg = "Yo yo! You found an unhandled test case!";
        }
        return arr.map((el,idx)=>{
          if(idx == i) {
            el.msg = msg;
          }
          return el;
        });
      });
  };

  const validate = (code,inp)=>{
    console.log(code);
    console.log(inp);
    if(inp == "") {
      inp = null;
    }
    let n = null;
    try {
      if(inp != null) {
        inp = inp.trim();
        if(inp.trim().startsWith("[") && inp.trim().endsWith("]")) {
          //continue
        } else {
          //must be a string
          inp = "[\""+inp+"\"]";
        }
      }
      n = JSON.parse(inp);
    } catch(error) {
      console.log(error);
      return -1;
    }
    if(typeof(n) == "object") {
      interpreter.import('inp', n);
    
      interpreter.run(`
        exports.mod=${code};
        `);
      let res = interpreter.exports.mod;
      console.log(res);
      return res;
    } else {
      return -1;
    }
  };

  const assertExpected = (code,cres,ires)=>{
    console.log(code);
    console.log(cres);
    console.log(ires);
    if(cres == "") {
      cres = null;
    }
    if(ires == "") {
      ires = null;
    }
    interpreter.import('cres', cres);
    interpreter.import('ires', ires);
    
    interpreter.run(`
      exports.mod=${code};
      `);
    let res = interpreter.exports.mod;
    console.log(res);
    return res;
  };

  const testInputMatches = (ccode,icode,inp,acode)=>{
    // Parse and run the code
    console.log(ccode);
    console.log(acode);
    console.log(icode);
    console.log(inp);
    if(inp == "") {
      inp = null;
    }
    let n = null;
    try {
      if(inp != null) {
        inp = inp.trim();
        if(inp.trim().startsWith("[") && inp.trim().endsWith("]")) {
          //continue
        } else {
          //must be a string
          inp = "[\""+inp+"\"]";
        }
      }
      n = JSON.parse(inp);
    } catch(error) {
      console.log(error);
      return -1;
    }
    if(typeof(n) == "object") {
      interpreter.import('inp', n);
    
      interpreter.run(`
        exports.mod=${icode};
        `);
      let ires = interpreter.exports.mod;
      console.log(ires);
      interpreter.run(`
        exports.mod=${ccode};
        `);
      let cres = interpreter.exports.mod;
      console.log(cres);
      return assertExpected(acode,cres,ires);
    } else {
      return true;
    }
  };

  let dLinks = posts.map((v,i)=>{
		return <div key={"plt"+i} style={{paddingTop:"1rem",paddingBottom:"1rem"}}><div style={{fontSize:"1rem"}}><a href={"#pltp"+i}>{v.title}</a></div></div>
	});

	let dPosts = posts.map((v,i)=>{
    let mdt = new Date(v.lastModifyDt.seconds * 1000);
    let fdt = mdt.getFullYear()+"/"+(mdt.getMonth()+1)+"/"+mdt.getDate();
    let sn = 0;
		return <div key={"pltp"+i} id={"pltp"+i} style={{paddingTop:"1rem",paddingBottom:"1rem"}}>
            <div style={{fontSize:"1rem"}}>{v.title}</div>
            <div style={{fontSize:"0.60rem",lineHeight:"2rem"}}>Last modified on {fdt}.</div>
            <div style={{fontSize:"0.8rem",lineHeight:"1rem"}}>{parse(v.background)}</div>
            <div style={{fontSize:"0.8rem",lineHeight:"1rem"}}>&nbsp;</div>
            <div style={{fontSize:"0.8rem",lineHeight:"1rem"}}><button className="button" onClick={(e)=>{toggleSolution(e,i)}}>Hint</button></div>
            {toggle[i]?<div style={{fontSize:"0.8rem",lineHeight:"1rem"}}>{parse(v.solution)}</div>:""}
            <div style={{fontSize:"0.8rem",lineHeight:"1rem"}}>&nbsp;</div>
            <div style={{fontSize:"0.8rem",lineHeight:"1rem"}}><input key={"tinp"+i} type="text" onChange={(e)=>{handleInput(e,i)}} /> <button className="button" key={"tbutton"+i} onClick={(e)=>{handleSubmit(e,i)}}>Submit</button></div>
                      <div style={{fontSize:"0.8rem",lineHeight:"1rem"}}>&nbsp;</div>
                      <div key={"msg"+i} style={{fontSize:"0.8rem",lineHeight:"1rem"}}>{v.msg}</div>
                    </div>
	});

  return (
    props.loaded?
    <div className="App-section">
        <div className="container">
            <div className="left">&nbsp;</div>
            <div className="content">{dLinks}<div>&nbsp;</div>{dPosts}</div>
            <div className="right">&nbsp;</div>
        </div>
    </div>:<div>Loading...</div>
  );
}

export default Testercise;


